import React, { useContext } from "react";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";

const TouchNavBackground = () => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);
  const { navAnimating } = appContext;
  const { device, scrollingDown } = documentContext;

  return (
    <>
      {device !== `desktop` && !navAnimating && (
        <div
          className={`touch-nav__background ${
            scrollingDown ? `` : `visible`
          } w-full fixed top-0 right-0 left-0 z-30 bg-white`}
        ></div>
      )}
    </>
  );
};

export default TouchNavBackground;
