import React from "react";
import { PropTypes } from "prop-types";

const ArrowDown = ({ className, color }) => (
  <svg className={className} viewBox="0 0 8 5" fill="none">
    <path d="M4 5L0.535899 0.5L7.4641 0.5L4 5Z" fill={color} />
  </svg>
);

ArrowDown.defaultProps = {
  className: ``,
  color: ``
};

ArrowDown.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default ArrowDown;
