/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import { Location, navigate } from "@reach/router";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import ArrowDown from "~components/svg/ArrowDown";
import TouchNavBackground from "~components/TouchNavBackground";
import { fancyError } from "~utils/helpers";

import locales from "~data/locales";

class LanguageWidgetComponent extends Component {
  state = {
    active: false
  };

  componentDidMount() {
    const { appContext, location } = this.props;

    const activeLocale = this.parseLocaleFromPath(location.pathname);

    if (activeLocale) {
      appContext.setLocale(activeLocale);
    } else {
      appContext.setLocale(appContext.getDefaultLocale());
    }
  }

  //

  changeLocale = newLocale => {
    const { appContext, location } = this.props;

    //

    if (appContext.menuActive) {
      appContext.setMenuActive(false);

      clearAllBodyScrollLocks();
    }

    //

    appContext.setLocale(newLocale);

    let newPath;

    if (newLocale.default) {
      const locationSplit = location.pathname.split(`/`);

      if (locationSplit.length <= 2) {
        fancyError(`Unexpected URL parse result`);

        return;
      }

      newPath = location.pathname.replace(`/${locationSplit[1]}`, ``);
    } else {
      newPath = `/${newLocale.path}${location.pathname}`;
    }

    navigate(newPath);
  };

  parseLocaleFromPath = path => {
    const pathSplit = path.split(`/`);

    let detectedLocale = null;

    if (pathSplit.length >= 2) {
      const localeFromURL = pathSplit[1];

      if (locales?.[localeFromURL]) {
        detectedLocale = locales[localeFromURL];
      }
    }

    return detectedLocale;
  };

  //

  render() {
    const { appContext, documentContext } = this.props;
    const { menuActive, newsActive, navAnimating } = appContext;
    const { device, scrollingDown } = documentContext;
    const { active } = this.state;
    const visible = documentContext.scrollTop < 50;

    const color = appContext.languageWidgetColor;

    return (
      <>
        {(device === `desktop` && (
          <>
            {!menuActive && !newsActive && !navAnimating && (
              <article
                className={`language-widget transition-opacity-transform ${
                  active ? `active` : ``
                } ${visible ? `visible` : ``} fixed top-0 right-0 z-50`}
                onMouseEnter={() => this.setState({ active: true })}
                onMouseLeave={() => this.setState({ active: false })}
              >
                <div className="relative flex flex-col justify-start pr-4">
                  <ArrowDown
                    className="w-2 absolute right-0 top-0 mt-2 pointer-events-none"
                    color={color}
                  />

                  {appContext.locale && appContext.locale.locale && (
                    <div
                      className={`language-widget__text pointer-events-none b3 text-${color} ${
                        active ? `opacity-25` : ``
                      }`}
                    >
                      {appContext.locale.locale}
                    </div>
                  )}

                  <ul>
                    {Object.keys(locales).map(localeKey => {
                      const locale = locales[localeKey];

                      return (
                        <li key={locale.path}>
                          {locale !== appContext.locale && (
                            <button
                              key={locale.path}
                              type="button"
                              className={`language-widget__button language-widget__text ${
                                active
                                  ? `animation-fade`
                                  : `pointer-events-none`
                              } b3 text-left text-${color}`}
                              onClick={() => this.changeLocale(locale)}
                            >
                              {locale.locale}
                            </button>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </article>
            )}
          </>
        )) || (
          <>
            <TouchNavBackground />

            {!newsActive && !navAnimating && (
              <article
                role="presentation"
                className={`language-widget transition-opacity-transform ${
                  active ? `active` : ``
                } ${menuActive || visible ? `visible` : ``} fixed z-50`}
                onClick={() => this.setState({ active: !active })}
              >
                <div className="relative flex flex-col justify-start pr-4">
                  <ArrowDown
                    className="w-2 absolute right-0 top-0 mt-4 pointer-events-none"
                    color={menuActive || !scrollingDown ? `black` : color}
                  />

                  {appContext.locale && appContext.locale.locale && (
                    <div
                      className={`language-widget__current pointer-events-none mt-3 b2 text-${
                        menuActive || !scrollingDown ? `black` : color
                      } ${active ? `opacity-25` : ``}`}
                    >
                      {appContext.locale.locale}
                    </div>
                  )}

                  <ul>
                    {Object.keys(locales).map(localeKey => {
                      const locale = locales[localeKey];

                      return (
                        <li key={locale.path}>
                          {locale !== appContext.locale && (
                            <button
                              key={locale.path}
                              type="button"
                              className={`language-widget__button ${
                                active
                                  ? `animation-fade`
                                  : `pointer-events-none`
                              } b2 text-left text-${
                                menuActive || !scrollingDown ? `black` : color
                              }`}
                              onClick={() => this.changeLocale(locale)}
                            >
                              {locale.locale}
                            </button>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </article>
            )}
          </>
        )}
      </>
    );
  }
}

const LanguageWidget = () => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);

  return (
    <Location>
      {({ location }) => (
        <LanguageWidgetComponent
          appContext={appContext}
          documentContext={documentContext}
          location={location}
        />
      )}
    </Location>
  );
};

export default LanguageWidget;
