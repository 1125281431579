import React from "react";
import PropTypes from "prop-types";
import LanguageWidget from "~components/LanguageWidget";

const Layout = ({ children, className, location }) => {
  return (
    <>
      <LanguageWidget location={location} />

      <main className={`layout ${className}`}>{children}</main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  location: PropTypes.shape({}).isRequired
};

export default Layout;
